import React, { useState, useEffect } from "react";
import SsoHeader from "../../components/uikit/src/components/SsoHeader";
import SsoFooter from "../../components/uikit/src/components/SsoFooter";
import CompanyLogo from "../../assets/images/navigator-logo.png";
import EmailQueryImg from "../../assets/images/email-query-img.png";

import QuestionMarkIcon from "../../assets/images/question-octagon.svg";
import NotificationIcon from "../../assets/images/notification-outline-badged.svg";
import LogoutIcon from "../../assets/images/Logout.svg";
import UserIcon from "../../assets/images/User.svg";


import CardSlider from "./CardSlider.jsx";
import NavCardLayout from "./NavCardLayout.jsx";
import EmailQueryLayout from "./EmailQueryLayout.jsx";
import { Link as ScrollLink, Element as ScrollElement } from "react-scroll";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import {
  CardContainerHead,
  CardsListing,
} from "../../constants";

import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AuthService, { tokenValidationApi } from "../../services/AuthService";
import config from "../../services/config.js";

export default function LandingPage() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["inizionavigator"]);
  const UserName =
    cookies.inizionavigator && cookies.inizionavigator.userLoginName;
  const UserEmail =
    cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
  const handleNavigation = async (navigationPath, application) => {
    let app = '';
    if (application === 'Indication') {
      app = 'ipt';
    } else if (application === 'Asset') {
      app = 'apt';
    } else if (application === 'Clarity') {
      app = 'voc';
    } else if (application === 'Quant') {
      app = 'quant';
    }

    if (navigationPath === "#") {
      navigate(navigationPath);
    } else {
      await config.post('/api/user-track', { email: UserEmail, application: app }, { headers: { 'Authorization': `Bearer ${cookies.inizionavigator.token}` } })
      window.location.replace(navigationPath)
    }
  };
  const HandleLogout = async () => {
    await AuthService.Logout(UserEmail);
    removeCookie(`inizionavigator`, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    navigate("/login");
  };


  useEffect(() => {
    async function validateToken(token) {
      try {
        const payload = {
          token: token,
        };
        const response = await tokenValidationApi(payload);
        if (response?.data?.is_success == true) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }

    async function HandleSSORedirection() {
      if (cookies.inizionavigator && cookies.inizionavigator.token) {
        let tokenValidation = await validateToken(
          cookies.inizionavigator && cookies.inizionavigator.token
        );

        if (!tokenValidation) {
          return navigate("/login");
        }

//         const isAdmin = await AuthService.IsAdmin(
//           cookies.inizionavigator && cookies.inizionavigator.email,
//           cookies.inizionavigator && cookies.inizionavigator.token
//         );
//         console.log("🚀 ~ HandleSSORedirection ~ isAdmin:", isAdmin)
// debugger;
//         if (isAdmin) {
//           return navigate("/admin", {
//             state: {
//               isAdmin: true,
//             },
//           });
//         }
      }
      else{
        navigate("/login");
      }
    }

    HandleSSORedirection();
  }, [cookies?.inizionavigator, navigate]);

  // #START: Scroll to ID and down to top section 
  // Scroll button active after scroll
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    // Change 200 to the number of pixels at which you want to show the button
    if (scrollPosition > 200) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const scrollToSection = (id) => {
  //   const scrollToElement = document.getElementById(id);
  //   if (scrollToElement) {
  //     scrollToElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // #END: Scroll to ID and down to top section 

  const isAdmin =
    cookies.inizionavigator && cookies.inizionavigator.isAdmin;

  if (isAdmin) {
    return <Navigate to="/admin" />;
  }

  return (
    <>
      <ScrollElement name="header" className="element">
        <SsoHeader
          iconsList={[QuestionMarkIcon, NotificationIcon]}
          logoutIcon={LogoutIcon}
          UserIcons={UserIcon}
          logo={CompanyLogo}
          useremail={UserEmail}
          username={UserName}
          logoutCallback={HandleLogout}
        />
      </ScrollElement>

      <div className="about-container">
        <div className="container hgt-inherit">
          <div className="row hgt-inherit">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-6 hgt-inherit">
              <section className="hp-banner-text hgt-inherit">
                <p className="fade-in wc-ttl">Hey {UserName} !</p>
                <div className="wc-mg-text">
                  <span>Welcome to</span>
                  <span>Navigator.ai</span>
                </div>
                <p className="fade-in delayed wc-data-text">
                  InizioNavigator.ai is a data platform built using latest
                  technology to house a real-time <span>“Data Universe”.</span>
                </p>
              </section>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-6 d-flex align-items-center justify-content-center">
              <div className="wc-banner-img-section">
                <div className="slider-thumb-up"></div>
                <div className="slider-thumb-down"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollElement name="newUpdates" className="element">
        <div className="card-slider-section">
          <h2>New Updates</h2>
          <CardSlider />
        </div>
      </ScrollElement>

      <ScrollElement name="ourSolutions" className="element">
        <div className="card-container">
          <h2>{CardContainerHead}</h2>
          <div className="card-box-center-align">
            <div className="row align-items-start">
              {CardsListing.slice(0, 5).map((car) => (
                <div
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3"
                  onClick={() => handleNavigation(car.navigationPath, car.props.cardDetails.heading)}
                >
                  <NavCardLayout cardProps={car.props} />
                </div>
              ))}
            </div>
            {/* <div className="row flex-center">
              {CardsListing.slice(4, 8).map((car) => (
                <div
                  className="col-md-3"
                  onClick={() => handleNavigation(car.navigationPath)}
                >
                  <NavCardLayout cardProps={car.props} />
                </div>
              ))}
            </div>
            <div className="row flex-center">
              {CardsListing.slice(8, 9).map((car) => (
                <div
                  className="col-md-3"
                  onClick={() => handleNavigation(car.navigationPath)}
                >
                  <NavCardLayout cardProps={car.props} />
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </ScrollElement>
      <ScrollElement name="contactUs" className="element">
        <div className="email-query-container">
          <div className="email-sec">
            <div className="email-qus-img-wd">
              <img
                src={EmailQueryImg}
                className="img-fluid"
                alt="email-query"
              />
            </div>
            <div className="d-flex flex-column email-section">
              <div className="help-corner">Help &amp; Suggestion Corner  </div>
              <div className="any-questions">Got Any Question or Suggestion</div>
              <div className="ask-text">Let us know your questions or drop a suggestion as well. </div>
              <EmailQueryLayout />
            </div>
          </div>
        </div>
      </ScrollElement>
      <SsoFooter />

      {showScrollButton && (
        <div className="scroll-up-arrow" onClick={scrollToTop}>
          <div className="up-arrow-bg">
            <ArrowUpwardIcon style={{ color: "#fff" }} />
          </div>
        </div>
      )}
    </>
  );
}
